import React, { useEffect, useState } from "react";
import { getCall } from "../../helpers/axiosUtils";
import { BASE_URL } from "../../helpers/constants";

const NotificationsPage = () => {
  const [notis, setNotis] = useState([]);

  useEffect(() => {
    getCall(BASE_URL + "api/notifications").then((resp) => {
      if (resp.status == 200) {
        const data = resp.data;
        console.log(data);
        if (data.success) {
          setNotis(data["data"]);
          console.log(data);
        }
      }
    });
  }, [""]);

  return (
    <div style={{ padding: "1em" }}>
      <h6>Notifications</h6>
      <div className="mt-3"></div>
      {notis.map((notification) => {
        return (
          <div
            style={{
              marginTop: "1em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Notification
              title={notification.title}
              body={notification.body}
              time={notification.created_at.split("T")[1].slice(0, 5)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default NotificationsPage;

const Notification = ({ title, body, time }) => {
  return (
    <div
      className="noti"
      style={{
        // padding: "10px 20px",
        // maxWidth: "1200px",
        width: "100%",

        // boxShadow: "0px 1px 6px 2px #bbb6b6a1",
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          className="title flex justify-between"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontWeight: "505" }}>{title}</div>
          <div className="time" style={{ fontSize: "0.8em", color: "gray" }}>
            {time}
          </div>
        </div>
        <div className="body" style={{ marginTop: "5px" }}>
          <div style={{ fontSize: "0.9em" }}>{body}</div>
        </div>
      </div>
    </div>
  );
};
